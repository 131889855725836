<div class="container">
  <div class="row" style="margin: 90px 20px -60px 20px;">

    <div class="col-9 col-md-5 col-lg-4 image">
      <img src="assets\Ilker_Hadzhalaran.jpg">
    </div>

    <div class="col-12 col-lg-8 rounded-border">
      <h1>Hi, I'm Ilker Hadzhalaran...</h1>
      <p>
        I'm passionate about software development, automation, and learning new
        things. I'm located in the Greater Toronto Area and I hold a Bachelor of Engineering Technology in Automation
         Engineering from McMaster University. I'm currently seeking a new opportunity as a full-time software developer.
      </p>

      <div class="row" style="margin-top: 30px;">
        <div class="col-12 text-center">
          <div class="hide-on-small-screens">
            <a href="https://github.com/ilker-h" target="_blank"> <i class="fab fa-github fa-3x icons"
                matTooltip="GitHub (the code for my projects)"></i> </a>
            <a href="https://www.linkedin.com/in/ilkerhadzhalaran/" target="_blank"> <i
                class="fab fa-linkedin fa-3x icons" matTooltip="LinkedIn"></i> </a>
            <a href="https://vimeo.com/channels/1525169" target="_blank"> <i class="fab fa-vimeo fa-3x icons"
                matTooltip="Vimeo (video walkthroughs of my projects)"></i> </a>
            <button mat-flat-button (click)="downloadFile()">Download Resume</button>
          </div>
        </div>
        <div class="col-12 text-center" style="margin-bottom: 10px;">
          <div class="hide-on-bigger-screens">
            <a href="https://github.com/ilker-h" target="_blank"> <i class="fab fa-github fa-3x icons"
                matTooltip="GitHub (the code for my projects)"></i> </a>
            <a href="https://www.linkedin.com/in/ilkerhadzhalaran/" target="_blank"> <i
                class="fab fa-linkedin fa-3x icons" matTooltip="LinkedIn"></i> </a>
            <a href="https://vimeo.com/channels/1525169" target="_blank"> <i class="fab fa-vimeo fa-3x icons"
                matTooltip="Vimeo (video walkthroughs of my projects)"></i> </a>
          </div>
        </div>
        <div class="col-12 text-center" style="margin-top: 10px;">
          <div class="hide-on-bigger-screens">
            <button mat-flat-button (click)="downloadFile()">Download Resume</button>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
